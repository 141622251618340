<template>
  <v-container fluid>
    <UpdateLogoDialog v-bind:open-dialog="updateLogoDialog" :agencyId="agency.agencyId"
                      v-on:close-dialog="updateLogoDialog=false"
                      v-on:update-agency="updateAgencyLogo"></UpdateLogoDialog>
    <DebtDischargeDialog v-bind:open-dialog="debtDischargeDialog" :agency="agency" :payers="getAgencyPayers"
                         :agencyOrders="agencyOrders" v-on:close-dialog="debtDischargeDialog=false"
                         v-on:discharge-debt="dischargeDebt($event)"></DebtDischargeDialog>
    <DeleteConfirmationDialog :object-name="'agency'" :agency="agency"
                              v-on:close-dialog="deleteDialog=false" v-bind:open-dialog="deleteDialog"
                              v-on:delete-object="deleteObject"></DeleteConfirmationDialog>
    <v-row justify="center" class="ma-auto">
      <v-col cols="12" md="8" sm="8">
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row class="ma-auto">
              <v-col md="8">
                <div class="font-weight-light" style="text-transform: uppercase">
                  <v-chip label color="indigo">
                    <h2 v-if="!$vuetify.breakpoint.mobile">{{agency.agencyName}}</h2>
                    <h3 v-if="$vuetify.breakpoint.mobile">{{agency.agencyName}}</h3>
                  </v-chip>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="4" sm="4">
                <v-chip :color="calculateDebt>0?'warning':'indigo'" text-color="white"
                        style="float: right;text-transform: uppercase" label>
                  <h2 v-if="!$vuetify.breakpoint.mobile">{{$t('agency-card.debt')}}:
                    {{loading?$t('agency-card.calculating'):calculateDebt}}</h2>
                  <h3 v-if="$vuetify.breakpoint.mobile">{{$t('agency-card.debt')}}:
                    {{loading?$t('agency-card.calculating'):calculateDebt}}</h3>
                </v-chip>
              </v-col>
            </v-row>
            <v-row class="ma-auto">
              <v-col md="8">
                <div class="font-weight-light" style="text-transform: uppercase">
                  <h3 v-if="!$vuetify.breakpoint.mobile">[{{agency.city}}]</h3>
                  <h4 v-if="$vuetify.breakpoint.mobile">[{{agency.city}}]</h4>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="4">
                <div class="font-weight-light" style="float:right; text-transform: uppercase">
                  <h3 v-if="!$vuetify.breakpoint.mobile">{{agency.prefix}}</h3>
                  <h4 v-if="$vuetify.breakpoint.mobile">{{agency.prefix}}</h4>
                </div>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="ma-auto" justify="center">
              <v-col>
                <div class="subtitle-1 font-weight-light">
                  <a :href="`mailto:${agency.email}`" style="color: white"
                     class="no-underline-link">{{agency.email}}</a>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <div class="subtitle-1 font-weight-light">
                  <a :href=agency.website target="_blank" style="color: white;float: right"
                     class="no-underline-link">{{agency.website}}</a>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="ma-auto">
              <v-col sm="12" md="6">
                <MenuDropdown></MenuDropdown>
              </v-col>
              <v-col sm="12" md="6">
                <ActionButtonGroup v-on:enable-edit="readonly=false" object="isAgency"
                                   agencyId="agency.agencyId"
                                   v-on:clone-dialog="cloneDialog=true"
                                   v-on:create-agency-invoice="createAgencyInvoice"
                                   v-on:open-update-logo-dialog="updateLogoDialog=true"
                                   v-on:open-delete-dialog="openDeleteDialog()"
                                   v-on:open-debt-discharge-dialog="debtDischargeDialog=true"></ActionButtonGroup>
              </v-col>
            </v-row>
          </template>

          <v-form>
            <v-container fluid>
              <v-row dense>
                <v-tabs grow icons-and-text center-active centered show-arrows>
                  <v-tab v-for="card in cards" :key="card.id">{{card.title}}
                    <v-icon :color="card.color">{{card.icon}}</v-icon>
                  </v-tab>

                  <v-tab-item>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            :label="$t('agency-card.agency-name')"
                            v-model="agency.agencyName"
                            :readonly="readonly"/>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            :label="$t('agency-card.city')" v-model="agency.city"
                            :readonly="readonly"/>
                        </v-col>

                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="agency.address" :label="$t('agency-card.address')"
                            :readonly="readonly"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>

                  <v-tab-item>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-text-field
                            :label="$t('agency-card.email')" v-model="agency.email"
                            :readonly="readonly"/>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            :label="$t('agency-card.mobile')" v-model="agency.mobile"
                            :readonly="readonly"/>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="agency.phone" :label="$t('agency-card.phone')"
                            :readonly="readonly"/>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="agency.website" :label="$t('agency-card.website')"
                            :readonly="readonly"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>

                  <v-tab-item>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :label="$t('agency-card.skype')" v-model="agency.skype"
                            :readonly="readonly"/>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            :label="$t('agency-card.viber')" v-model="agency.viber"
                            :readonly="readonly"/>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="agency.telegram"
                            :label="$t('agency-card.telegram')"
                            :readonly="readonly"/>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            :label="$t('agency-card.facebook')"
                            v-model="agency.facebook"
                            :readonly="readonly"/>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            :label="$t('agency-card.instagram')"
                            v-model="agency.instagram"
                            :readonly="readonly"/>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="agency.linkedin"
                            :label="$t('agency-card.linkedin')"
                            :readonly="readonly"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>

                  <v-tab-item>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="calculationType" :items="calculationTypes"
                            :label="$t('agency-card.calculation-type')"
                            required :readonly="readonly"
                            item-text="calculationTypeName"
                            item-value="calculationTypeId" return-object>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="agency.agencyType" :items="agencyTypes" :readonly="readonly"
                            :label="$t('agency-card.agency-type')" required clearable>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>

                  <v-tab-item>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12">
                          <v-textarea v-model="agency.comments" class="purple-input"
                                      :label="$t('agency-card.comments')" clearable counter
                                      rows="2"
                                      :readonly="readonly"/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>

                  <v-tab-item>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :label="$t('agency-card.created-date')"
                            v-model="createdTime" readonly/>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            :label="$t('agency-card.modified-date')"
                            v-model="modifiedTime" readonly/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-tab-item>
                </v-tabs>
              </v-row>
              <v-row>
                <v-col class="text-right">
                  <v-btn color="primary" block @click="disabled=true" :disabled="disabled">
                    {{$t('buttons.cancel')}}
                  </v-btn>
                </v-col>
                <v-col class="text-right">
                  <v-btn color="primary" block @click="update" :disabled="readonly">
                    {{$t('buttons.update')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4" sm="4">
        <base-material-card color="primary" class="v-card-profile">
          <template v-slot:heading>
            <v-row class="ma-auto">
              <v-col cols="12" md="12" sm="12" lg="12">
                <v-avatar color="primary" rounded size="100%" max-width="100%">
                  <img :src="agencyImageRender.icon">
                </v-avatar>
              </v-col>
            </v-row>
          </template>
          <v-card-text class="text-center">

            <h4 v-if="!$vuetify.breakpoint.mobile" class="display-2 font-weight-light mb-3 black--text"
                style="text-transform: uppercase">
              {{agency.agencyName}}
            </h4>
            <h4 v-if="$vuetify.breakpoint.mobile" class="font-weight-light black--text"
                style="text-transform: uppercase">
              {{agency.agencyName}}
            </h4>
            <h6 class="display-1 mb-1 grey--text" style="text-transform: uppercase">
              {{agency.city}}
            </h6>
            <v-divider></v-divider>
          </v-card-text>

          <v-row justify="center" class="ma-auto">
            <v-expansion-panels popout focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>{{$t('agency-card.orders')}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <OrderRelatedRecords :agencyId="agency.agencyId" :objectName="objectName"
                                       :orders="agencyOrders"
                                       :loading="loading"></OrderRelatedRecords>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>{{$t('agency-card.managers')}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ManagerRelatedRecords :agencyId="agency.agencyId"
                                         :managers="managers"></ManagerRelatedRecords>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>{{$t('agency-card.payers')}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <PayerRelatedRecords :agencyId="agency.agencyId"
                                       :payers="getAgencyPayers"></PayerRelatedRecords>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import OrderRelatedRecords from "../../components/expansion-panels/OrderRelatedRecords";
  import ManagerRelatedRecords from "../../components/expansion-panels/ManagerRelatedRecords";
  import PayerRelatedRecords from "../../components/expansion-panels/PayerRelatedRecords";
  import ActionButtonGroup from "../../components/buttons/ActionButtonGroup";
  import MenuDropdown from "../dashboard/component/MenuDropdown";
  import UpdateLogoDialog from "./UpdateLogoDialog";
  import DebtDischargeDialog from "./DebtDischargeDialog";
  import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
  import {mapState} from "pinia";
  import {useOrders} from "../../stores/ordersStore";
  import {useAgencies} from "../../stores/agenciesStore";
  import {usePayers} from "../../stores/payersStore";
  import {useManagers} from "../../stores/managersStore";
  import {useTasks} from "../../stores/tasksStore";
  import {useFiles} from "../../stores/filesStore";

  const agencyStore = useAgencies()
  const ordersStore = useOrders()
  const payersStore = usePayers()
  const managersStore = useManagers()
  const filesStore = useFiles()
  const tasksStore = useTasks()

  let moment = require('moment');
  export default {
    name: "AgencyCard",
    components: {
      DebtDischargeDialog,
      DeleteConfirmationDialog,
      UpdateLogoDialog,
      MenuDropdown,
      ActionButtonGroup,
      PayerRelatedRecords, ManagerRelatedRecords, OrderRelatedRecords
    },
    data() {
      return {
        deleteDialog: false,
        calculationType: [],
        calculationTypes: [],
        debtSum: 0,
        updateLogoDialog: false,
        debtDischargeDialog: false,
        cards: [
          {
            id: 1,
            title: this.$t('agency-card-tabs.main-info'),
            color: 'indigo',
            flex: 12,
            icon: 'mdi-folder-information-outline'
          },
          {
            id: 2,
            title: this.$t('agency-card-tabs.contact-details'),
            color: 'green',
            flex: 12,
            icon: 'mdi-card-account-phone-outline'
          },
          {
            id: 3,
            title: this.$t('agency-card-tabs.social-networking'),
            color: 'deep-orange',
            flex: 12,
            icon: 'mdi-facebook'
          },
          {
            id: 4,
            title: this.$t('agency-card-tabs.defaults'),
            color: 'blue',
            flex: 12,
            icon: 'mdi-page-previous-outline'
          },
          {
            id: 5,
            title: this.$t('agency-card-tabs.comments'),
            color: 'pink darken-3',
            flex: 12,
            icon: 'mdi-comment-processing-outline'
          },
          {
            id: 6,
            title: this.$t('agency-card-tabs.system-info'),
            color: 'purple darken-4',
            flex: 12,
            icon: 'mdi-table-cog'
          },
        ],
        objectName: 'isAgency',
        agency: [],
        agencyOrders: [],
        statuses: [],
        readonly: true,
        disabled: true,
        fileCreatedTime: '',
        fileModifiedTime: '',
        createdTime: '',
        modifiedTime: '',
        closeOnClick: true,
        loading: false,
        agencyTypes: [this.$t('agency-types.legal-entity'), this.$t('agency-types.fop'), this.$t('agency-types.private-person')],
      }
    },
    methods: {
      openDeleteDialog() {
        this.deleteDialog = true;
      },
      deleteObject() {
        let agencyId = this.agency.agencyId;
        let action = 'delete';
        let objectType = 'Agency';
        let params = new URLSearchParams();
        params.append('agencyId', agencyId);
        AXIOS.delete('/api/deleteAgency', {params})
          .then(response => {
            agencyStore.deleteAgency(agencyId);
            this.deleteItemsFromStores(agencyId);
            this.userNotification(response.data, action, objectType);
            this.$router.push({name: 'Orders'})
          })
      },
      deleteItemsFromStores(agencyId) {
        let orders = this.getAgencyOrders();
        let managers = this.managers.filter(e => e.agency.agencyId === agencyId);
        let payers = this.payers.filter(e => e.agency.agencyId === agencyId);
        let filteredTasks = [];
        let filteredFiles = [];
        for (let i = 0; i < orders.length; i++) {
          if (this.files) {
            let f = this.files.filter(e => e.order.orderId === orders[i].orderId)
            f.forEach(e => filteredFiles.push(e))
          }
          if (this.tasks) {
            let t = this.tasks.filter(e => e.order !== null && e.order.orderId === orders[i].orderId);
            t.forEach(e => filteredTasks.push(e))
          }
        }
        ordersStore.deleteMultipleOrders(orders);
        filesStore.deleteMultipleFiles(filteredFiles);
        managersStore.deleteMultipleManagers(managers);
        payersStore.deleteMultiplePayers(payers);
        tasksStore.deleteMultipleTasks(filteredTasks);
      },
      async updateNeededCheck() {
        if (this.agencies.length) {
          this.agency = this.agencies.filter(e => e.agencyId == this.$route.params.agencyId)[0];
          this.getEntities();
          this.parseTime();
        }
        if (!this.agencies.length) {
          let res = await agencyStore.getAgenciesAsync()
          this.agency = res.data.filter(e => e.agencyId == this.$route.params.agencyId)[0];
          this.getEntities();
          this.parseTime();
          this.parseTime();
        }
        if (!this.payers.length) {
          payersStore.populatePayers()
        }
        if (!this.managers.length) {
          managersStore.populateManagers()
        }
        if (!this.orders.length) {
          await ordersStore.getOrdersAsync()
        }
      },
      dischargeDebt(args) {
        let action = 'discharge';
        let objectType = 'Debt'
        let format = "YYYY-MM-DD HH:mm:ss";
        let paymentDate = moment(args[2]).format(format);
        let json = JSON.stringify(args[0]);
        let params = new URLSearchParams();
        let statusId = this.statuses.filter(e => e.statusName === 'Paid')[0].statusId;
        params.append('statusId', statusId);
        params.append("filteredOrders", json);
        params.append('payerId', args[1]);
        params.append('paymentDate', paymentDate);
        AXIOS.post(`/api/dischargeDebtOrders`, params)
          .then(response => {
            this.debtDischargeDialog = false;
            ordersStore.dischargeOrdersDebt(response.data);
            this.userNotification(response.data, action, objectType);
          });
        this.getAgencyOrders();
      },
      getEntities() {
        if (this.agency.calculationType) {
          this.calculationType = this.agency.calculationType;
        }
      },
      parseTime() {
        this.createdTime = moment(this.agency.createdTime).format("DD.MM.YYYY HH:mm");
        this.modifiedTime = moment(this.agency.modifiedTime).format("DD.MM.YYYY HH:mm");
      },
      getAgencyOrders() {
        console.log("agen:" + this.agency.agencyId)
        console.log("this.orders:" + this.orders.length)
        this.agencyOrders = this.orders.filter(e => e.agency.agencyId === this.agency.agencyId);
        console.log("agencyOrders2:" + this.agencyOrders.length)
        return this.agencyOrders;
      },
      updateAgencyLogo(logoPath) {
        this.agency.logoPath = logoPath;
        this.update();
      },
      createAgencyInvoice() {
        this.$router.push({name: 'Invoicing', params: {agencyId: this.agency.agencyId}})
      },
      update() {
        let action = 'update';
        let objectType = 'Agency';
        let agency = this.agency;
        let params = new URLSearchParams();
        params.append('address', agency.address);
        params.append('agencyId', agency.agencyId);
        params.append('comments', agency.comments);
        params.append('agencyName', agency.agencyName);
        params.append('city', agency.city);
        params.append('email', agency.email);
        params.append('logoPath', agency.logoPath);
        params.append('mobile', agency.mobile);
        params.append('phone', agency.phone);
        params.append('skype', agency.skype);
        params.append('telegram', agency.telegram);
        params.append('viber', agency.viber);
        params.append('website', agency.website);
        params.append('facebook', agency.facebook);
        params.append('instagram', agency.instagram);
        params.append('linkedin', agency.linkedin);
        params.append('prefix', agency.prefix);
        params.append('agencyType', agency.agencyType);
        params.append('active', agency.active);
        if (this.calculationType.length) {
          params.append('calculationTypeId', this.calculationType.calculationTypeId);
        }
        AXIOS.post(`/api/updateAgency`, params)
          .then(response => {
            const agencyStore = useAgencies()
            agencyStore.patchAgency(response.data);
            this.userNotification(response.data, action, objectType);
            this.readonly = true;
          })
      },
    },
    computed: {
      ...mapState(useOrders, ['orders']),
      ...mapState(useAgencies, ['agencies']),
      ...mapState(usePayers, ['payers']),
      ...mapState(useManagers, ['managers']),
      ...mapState(useFiles, ['files']),
      ...mapState(useTasks, ['tasks']),
      getAgencyPayers() {
        return this.payers.filter(e => e.agency.agencyId === this.agency.agencyId);
      },
      calculateDebt() {
        let debtOrders = this.getAgencyOrders().filter(e => e.status.statusName !== 'Paid');
        return debtOrders
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(0);
      },
      agencyImageRender() {
        return {
          ...this.agency,
          icon: this.agency.logoPath && require(`@/assets/agencylogos/${this.agency.logoPath}`)
        }
      }
    },
    created() {
      this.updateNeededCheck();
      this.getStatusList();
      this.getActiveCalculationTypes();
    },
  }
</script>

<style scoped>
  .no-underline-link {
    text-decoration: none;
  }
</style>
