var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"single-expand":"","item-key":"orderId","loading":_vm.loading,"multi-sort":"","headers":_vm.headers,"items":_vm.orders,"mobile-breakpoint":"1200","footer-props":{
                showFirstLastPage: true,
              }},scopedSlots:_vm._u([{key:"item.orderCode",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ path: '/order/' + item.orderId}}},[_vm._v(" "+_vm._s(item.orderCode)+" ")])]}},{key:"item.langPair",fn:function(ref){
              var item = ref.item;
return [_c('span',{key:_vm.parseLangPairs(item.langPair)},[_vm._v(_vm._s(_vm.finalString))])]}},{key:"item.orderDate",fn:function(ref){
              var item = ref.item;
return [_c('span',{key:_vm.parseDate(item.orderDate)},[_vm._v(_vm._s(_vm.parseDate(item.orderDate)))])]}},{key:"item.finishTime",fn:function(ref){
              var item = ref.item;
return [_c('span',{key:_vm.parseTime(item.finishTime)},[_vm._v(_vm._s(_vm.parseTime(item.finishTime)))])]}},{key:"item.statusIcon",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status.statusColor}},[_vm._v(" mdi-circle-medium ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }