<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <v-container fluid>
          <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="100px"></v-img>
          <v-card-title>
            <span class="headline" style="text-transform: uppercase">{{$t('dialogs.update-logo.update-logo')}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-file-input v-model="logoFile" accept="image/*" :label="$t('dialogs.update-logo.select-logo')"
                          persistent-hint
                          :hint="$t('dialogs.update-logo.select-logo-hint')">
            </v-file-input>
            <v-btn color="blue darken-1" @click="sendFile" :disabled="!logoFile">
              {{$t('buttons.update')}}
            </v-btn>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" v-on:click="$emit('close-dialog')">
              {{$t('buttons.close')}}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {AXIOS} from "../../../http-commons";

  export default {
    props: ['open-dialog', 'agencyId'],
    name: "UpdateLogoDialog",
    data: () => ({
      logoFile: null
    }),
    methods: {
      sendFile() {
        this.removeLogoFromFS(this.agencyId);
        const formData = new FormData();
        formData.append('file', this.logoFile);
        AXIOS.post('/api/logoUpload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            this.$emit('update-agency', response.data);
            this.$emit('close-dialog');
          })
      },
      removeLogoFromFS(agencyId) {
        let params = new URLSearchParams();
        params.append('agencyId', agencyId);
        AXIOS.post('/api/removeLogoFromFS/', params)
          .then(response => {
            this.response = response.data;
          })
      },
    },
  }
</script>

<style scoped>

</style>
