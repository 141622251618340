<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <v-container fluid>
          <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="100px"></v-img>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form>
              <v-card-title>
                <span
                  class="headline">{{$t('dialogs.debt-discharge.debt-discharge-header')}} {{agency.agencyName}}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ma-auto">
                  <v-col cols="12" sm="6" lg="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-autocomplete v-model="payerId" :items=filterPayers :label="$t('dialogs.debt-discharge.payer')"
                                      required
                                      item-text="payerName" item-value="payerId" clearable v-on:blur="collectArgs">
                      </v-autocomplete>
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" lg="6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <v-datetime-picker v-model="dischargeDate" :label="$t('dialogs.debt-discharge.discharge-date')"
                                         :timePickerProps="timePickerProps"
                                         :date-picker-props="datePickerProps" :text-field-props="textFieldProps"
                                         v-on:input="collectArgs">
                      </v-datetime-picker>
                      <span>{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-switch v-model="isPeriodRequired" inset
                              :label="$t('dialogs.debt-discharge.calculate-period')"></v-switch>
                  </v-col>
                </v-row>

                <v-row v-if="isPeriodRequired">
                  <v-col cols="12" sm="6" md="6">
                    <v-dialog ref="dialog" v-model="modal" :return-value.sync="dateRange" persistent width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider rules="required" v-slot="{ errors }">
                          <v-text-field
                            v-model="dateRangeText"
                            :label="$t('dialogs.debt-discharge.select-invoicing-range')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on" v-on:input="filterOrders">
                          </v-text-field>
                          <span>{{ errors[0] }}</span>
                        </validation-provider>
                      </template>
                      <v-date-picker :first-day-of-week="datePickerProps.firstDayOfWeek" v-model="dateRange" scrollable
                                     range>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false">
                          {{$t('buttons.cancel')}}
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(dateRange)">
                          {{$t('buttons.ok')}}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-row class="ma-auto" v-if="!invalid">
                  <v-col cols="12" sm="6" lg="6">
                    <div class="font-weight-light">
                      <h4>{{$t('dialogs.debt-discharge.number-of-orders')}}: {{filterOrders}}</h4>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" lg="6">
                    <div class="font-weight-light">
                      <h4>{{$t('dialogs.debt-discharge.discharge-total')}}: {{debtSum}}</h4>
                    </div>
                  </v-col>
                </v-row>

                <v-row class="ma-auto" v-if="!invalid && !filteredOrders.length">
                  <v-col cols="12" sm="12" lg="12">
                    <div class="font-weight-light">
                      <h4 style="color: red">{{$t('dialogs.debt-discharge.no-discharge-warning')}}</h4>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="invalid || !filteredOrders.length"
                       color="primary" v-on:click="$emit('discharge-debt',args)">
                  {{$t('buttons.discharge-debt')}}
                </v-btn>
                <v-btn color="blue darken-1" v-on:click="$emit('close-dialog')">
                  {{$t('buttons.close')}}
                </v-btn>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {required} from 'vee-validate/dist/rules';

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  export default {
    props: ['open-dialog', 'agency', 'payers', 'agencyOrders'],
    name: "DebtDischargeDialog",
    components: {ValidationProvider, ValidationObserver},
    data() {
      return {
        args: [],
        debtSum: null,
        isPeriodRequired: false,
        dateRange: [],
        payerId: null,
        filteredPayers: [],
        filteredOrders: [],
        dischargeDate: new Date(),
        modal: false,
        timePickerProps: {
          format: "24hr"
        },
        datePickerProps: {
          firstDayOfWeek: 1
        },
        textFieldProps: {
          prependIcon: 'mdi-clock-time-four-outline'
        },
      }
    },
    methods: {
      collectArgs() {
        this.args = [this.filteredOrders, this.payerId, this.dischargeDate];
        return this.args;
      },
      calculateDebt() {
        return this.debtSum = this.filteredOrders
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(0);
      },
    },
    computed: {
      dateRangeText() {
        return this.dateRange.join(' ~ ')
      },
      filterPayers: function () {
        this.filteredPayers = this.payers.filter(e => e.agency.agencyId === this.agency.agencyId);
        return this.filteredPayers;
      },
      filterOrders: function () {
        let startDate = new Date(this.dateRange[0]);
        let endDate = new Date(this.dateRange[1]);
        if (this.isPeriodRequired) {
          this.filteredOrders = this.agencyOrders.filter(e => e.status.statusName === 'Finished' &&
            new Date(e.orderDate).getTime() >= startDate.getTime() && new Date(e.orderDate).getTime() <= endDate.getTime());
        } else {
          this.filteredOrders = this.agencyOrders.filter(e => e.status.statusName === 'Finished');
        }
        this.calculateDebt();
        this.collectArgs();
        return this.filteredOrders.length;
      },
    },
  }
</script>
