<template>
  <v-data-table
    single-expand
    item-key="managerId"
    :loading="loading"
    :headers="headers"
    :items="getAgencyManagers"
    :search="search"
    mobile-breakpoint="1200"
    :footer-props="{showFirstLastPage: true}">
    <template v-slot:item.surname="{ item }">
      <router-link :to="{ path: '/manager/' + item.managerId}" style="text-decoration: none">
        {{item.surname}}
      </router-link>
    </template>
  </v-data-table>
</template>

<script>
  let moment = require('moment');

  export default {
    props: ['panelName', 'agencyId', 'managers'],
    name: "ManagerRelatedRecords",
    data() {
      return {
        filteredManagers: [],
        loading: false,
        search: '',
        headers: [
          {
            text: this.$t('tables.full-name'),
            sortable: true,
            value: 'surname',
            class: "primary--text"
          },
          {text: this.$t('tables.email'), value: 'email', class: "primary--text", sortable: false},
          {text: this.$t('tables.mobilePhone'), value: 'mobilePhone', class: "primary--text", sortable: false},
        ],
      }
    },
    computed: {
      getAgencyManagers() {
        return this.managers.filter(e => e.agency.agencyId === this.agencyId);
      },
    },
  }
</script>

<style scoped>

</style>
