<template>
  <v-data-table
    single-expand
    item-key="orderId"
    :loading="loading"
    multi-sort
    :headers="headers"
    :items="orders"
    mobile-breakpoint="1200"
    :footer-props="{
                showFirstLastPage: true,
              }">
<!--    <template v-slot:item.status="{ item }">-->
<!--      <v-btn :color="item.status.statusColor" dark small block>-->
<!--        {{ item.status.statusName }}-->
<!--      </v-btn>-->
<!--    </template>-->
    <template v-slot:item.orderCode="{ item }">
      <router-link :to="{ path: '/order/' + item.orderId}" style="text-decoration: none">
        {{item.orderCode}}
      </router-link>
    </template>
    <template v-slot:item.langPair="{ item }">
      <span :key="parseLangPairs(item.langPair)">{{finalString}}</span>
    </template>
    <template v-slot:item.orderDate="{ item }">
      <span :key="parseDate(item.orderDate)">{{parseDate(item.orderDate)}}</span>
    </template>
    <template v-slot:item.finishTime="{ item }">
      <span :key="parseTime(item.finishTime)">{{parseTime(item.finishTime)}}</span>
    </template>
    <template v-slot:item.statusIcon="{ item }">
      <v-icon :color="item.status.statusColor">
        mdi-circle-medium
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  let moment = require('moment');

  export default {
    props: ['panelName', 'agencyId', 'objectName', 'orders'],
    name: "OrderRelatedRecords",
    data() {
      return {
        loading: false,
        headers: [
          {
            text: this.$t('tables.status'),
            sortable: false,
            value: 'statusIcon',
            width: 2
          },
          {
            text: this.$t('tables.order-code'),
            align: 'start',
            sortable: false,
            value: 'orderCode',
            class: "primary--text"
          },
          {text: this.$t('tables.order-date'), value: 'orderDate', class: "primary--text"},
          {text: this.$t('tables.finish-time'), value: 'finishTime', class: "primary--text"},
          {text: this.$t('tables.short-pair'), value: 'langPair', class: "primary--text"},
          // {text: this.$t('tables.status'), value: 'status', class: "primary--text"},
        ],
      }
    },
    methods: {
      parseLangPairs(langPairArray) {
        this.finalString = ''
        let i;
        if (langPairArray.length !== 0) {
          for (i = 0; i < langPairArray.length; i++) {
            this.finalString = langPairArray[i].shortPairName + ', ' + this.finalString
          }
          this.finalString = this.finalString.substring(0, this.finalString.length - 2);
        }
      },
      parseDate(date) {
        moment.updateLocale(moment.locale(), {invalidDate: "Empty date"})
        return moment(date).format("DD.MM.YYYY");
      },
      parseTime(time) {
        moment.updateLocale(moment.locale(), {invalidDate: "Empty date"})
        return moment(time).format("DD.MM.YYYY HH:mm")
      },
      getAgencyOrders() {
        this.orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1);
      },
      getManagerOrders() {
        this.loading = true;
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        params.append('managerId', this.$route.params.managerId);
        AXIOS.get('/api/getManagerOrders', {params})
          .then(response => {
            this.orders = response.data;
            this.orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1);
            this.loading = false;
          })
      },
      getPayerOrders() {
        this.loading = true;
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        params.append('payerId', this.$route.params.payerId);
        AXIOS.get('/api/getPayerOrders', {params})
          .then(response => {
            this.orders = response.data;
            this.orders.sort((a, b) => (a.orderDate > b.orderDate) ? -1 : 1);
            this.loading = false;
          })
      },
    },
    created() {
      if (this.objectName === 'isManager') {
        this.getManagerOrders()
      }
      if (this.objectName === 'isAgency') {
        this.getAgencyOrders()
      }
      if (this.objectName === 'isPayer') {
        this.getPayerOrders()
      }
    },
  }
</script>

<style scoped>

</style>
