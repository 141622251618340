<template>
  <v-data-table
    single-expand
    item-key="payerId"
    :loading="loading"
    :headers="headers"
    :items="payers"
    :search="search"
    mobile-breakpoint="1200"
    :footer-props="{
                showFirstLastPage: true
              }">
    <template v-slot:item.payerName="{ item }">
      <router-link :to="{ path: '/payer/' + item.payerId}" style="text-decoration: none">
        {{item.payerName}}
      </router-link>
    </template>
  </v-data-table>
</template>

<script>
  let moment = require('moment');

  export default {
    props: ['payers'],
    name: "PayerRelatedRecords",
    data() {
      return {
        loading: false,
        search: '',
        headers: [
          {
            text: this.$t('tables.full-name'),
            align: 'start',
            sortable: true,
            value: 'payerName',
            class: "primary--text"
          },
          {
            text: this.$t('tables.comment'),
            sortable: true,
            value: 'comment',
            class: "primary--text"
          },
        ],
      }
    },
  }
</script>

<style scoped>

</style>
